import Link from 'next/link'
import {ctaEvent} from "../../../utils/events";

export default function Footer() {

    return (
        <>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <a href="/submit-extension" onClick={()=>ctaEvent("btf-cta")} className="submitirs cta-btn" id="btf-cta">Submit Tax Extension</a></div>
                    </div>
                </div>
            </div>

        </>
    )
}
